import { silentUnreachableError } from "utils/exceptions";
import * as Listing from "../Listing/types/State";
import * as Schema from "../../../../../../../../generic-states/Schema";
import * as State from "./types/State";
import * as Actions from "./types/Actions";

export function reducer(
  s: State.State,
  a: Actions.Actions,
): State.State | Listing.State {
  if (Schema.isActions(a)) {
    return s.type === "Ready:DataManager:DataTypes:Create:Ready"
      ? State.ready({
          ...s.payload,
          schema: Schema.reducer(s.payload.schema, a),
        })
      : s;
  }

  switch (a.type) {
    case "Ready:DataManager:DataTypes:Create:SetName":
      return s.type === "Ready:DataManager:DataTypes:Create:Ready"
        ? State.ready({ ...s.payload, name: a.payload })
        : s;
    case "Ready:DataManager:DataTypes:Create:SetDescription":
      return s.type === "Ready:DataManager:DataTypes:Create:Ready"
        ? State.ready({ ...s.payload, description: a.payload })
        : s;
    case "Ready:DataManager:DataTypes:Create:SetDefault":
      return s.type === "Ready:DataManager:DataTypes:Create:Ready"
        ? State.ready({ ...s.payload, default: a.payload })
        : s;
    case "Ready:DataManager:DataTypes:Create:SetEntity":
      return s.type === "Ready:DataManager:DataTypes:Create:Ready"
        ? State.ready({ ...s.payload, entity: a.payload })
        : s;
    case "Ready:DataManager:DataTypes:Create:Submit":
      return s.type === "Ready:DataManager:DataTypes:Create:Ready"
        ? State.saving(s.payload)
        : s;
    case "Ready:DataManager:DataTypes:Create:SaveFail":
      return s.type === "Ready:DataManager:DataTypes:Create:Saving"
        ? State.ready(s.payload)
        : s;
    case "Ready:DataManager:DataTypes:Create:SaveSuccess":
      return s.type === "Ready:DataManager:DataTypes:Create:Saving"
        ? Listing.init()
        : s;
    default:
      silentUnreachableError(a);
      return s;
  }
}
