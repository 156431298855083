import { JSONSchema6, JSONSchema6Definition } from "json-schema";
import { DataTypeSchema } from "types/src/DataType/DataTypeSchema";
import { Field } from "types/src/DataType/Field";
import { FieldConfig, FieldId, FieldType } from "types/src/DataType/FieldType";
import {
  DataType,
  DataTypeEntity,
  DataTypeId,
} from "types/src/DataType/DataType";
import { silentUnreachableError } from "utils/exceptions";
import { isT } from "fp-utilities";
import { jsonParse } from "utils/object";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/function";
import { toDate } from "types/src/date/ISODate";
import { TranslatedStr } from "types/src/TranslatedStr";
import {
  DataTypeEntity as ApiDataTypeEntity,
  DataTypeFragmentFragment,
} from "../generated/graphql";

function fieldTypeToJsonSchema(v: Field<FieldType>): JSONSchema6Definition {
  switch (v.type) {
    case "text": {
      const config = v.config as FieldConfig<"text">;
      return {
        type: "string",
        minLength: config.minLength,
        maxLength: config.maxLength,
      };
    }
    case "number": {
      const config = v.config as FieldConfig<"number">;
      return {
        type: "number",
        minimum: config.min,
        maximum: config.max,
      };
    }
  }
}

function fieldConfigToJsonSchema(v: Field<FieldType>): JSONSchema6Definition {
  switch (v.type) {
    case "text": {
      const config = v.config as FieldConfig<"text">;
      return {
        type: "object",
        properties: {
          type: {
            type: "string",
            default: v.type,
          },
          label: {
            type: "string",
            default: v.label,
          },
          description: {
            type: "string",
            default: v.description,
          },
          placeholder: {
            type: "string",
            default: config.placeholder,
          },
          order: {
            type: "number",
            default: v.order,
          },
        },
      };
    }
    case "number": {
      const config = v.config as FieldConfig<"number">;
      const schema: JSONSchema6 = {
        type: "object",
        properties: {
          type: {
            type: "string",
            enum: [v.type],
          },
          label: {
            type: "string",
            default: v.label,
          },
          description: {
            type: "string",
            default: v.description,
          },
          placeholder: {
            type: "string",
            default: config.placeholder,
          },
          order: {
            type: "number",
            default: v.order,
          },
        },
      };

      const step = pipe(
        config.step,
        O.fromNullable,
        O.map((v): JSONSchema6 => ({ type: "number", default: v })),
      );

      if (schema.properties && O.isSome(step)) {
        schema.properties.step = step.value;
      }

      return schema;
    }
  }
}

export function dataTypeToJsonSchema(
  title: string,
  description: string | undefined,
  schema: DataTypeSchema,
): JSONSchema6 {
  const fields = Object.values(schema.fields);

  return {
    $schema: "http://json-schema.org/draft-06/schema#",
    title,
    description,
    type: "object",
    properties: {
      fields: {
        type: "object",
        properties: fields.reduce(
          (acc, v) => {
            acc[v.id] = fieldTypeToJsonSchema(v);

            return acc;
          },
          {} as Record<string, JSONSchema6Definition>,
        ),
        required: fields.filter((v) => v.required).map((v) => v.id),
      },
      fieldsSettings: {
        type: "object",
        properties: fields.reduce(
          (acc, v) => {
            acc[v.id] = fieldConfigToJsonSchema(v);

            return acc;
          },
          {} as Record<string, JSONSchema6Definition>,
        ),
      },
    },
    required: ["fields"],
  };
}

export function dataTypeEntityToApiEntity(
  entity: DataTypeEntity,
): ApiDataTypeEntity {
  switch (entity) {
    case "customer":
      return ApiDataTypeEntity.Customer;
    case "order":
      return ApiDataTypeEntity.Order;
    case "delivery":
      return ApiDataTypeEntity.Delivery;
    case "item":
      return ApiDataTypeEntity.Item;
    case "repository":
      return ApiDataTypeEntity.Repository;
    case "supplier":
      return ApiDataTypeEntity.Supplier;
    case "movement":
      return ApiDataTypeEntity.Movement;
  }
}

function jsonSchemaToField(
  id: string,
  fields: JSONSchema6,
  fieldsSettings: JSONSchema6,
): Field<FieldType> | undefined {
  if (!fields?.properties?.[id]) return undefined;

  if (!fieldsSettings.properties?.[id]) return undefined;

  const field = fields.properties[id] as JSONSchema6;
  const settings = fieldsSettings.properties[id] as JSONSchema6;
  const type = (settings?.properties?.type as JSONSchema6)
    ?.default as FieldType;

  switch (type) {
    case "text": {
      return {
        id: id as FieldId,
        type: "text",
        label: (settings?.properties?.label as JSONSchema6)?.default as string,
        description: (settings?.properties?.description as JSONSchema6)
          ?.default as string,
        order: (settings?.properties?.order as JSONSchema6)?.default as number,
        required: fields?.required?.includes(id) || false,
        config: {
          minLength: field?.minLength,
          maxLength: field?.maxLength,
          placeholder: (settings?.properties?.placeholder as JSONSchema6)
            ?.default as string,
        },
      } satisfies Field<"text">;
    }
    case "number": {
      return {
        id: id as FieldId,
        type: "number",
        label: (settings?.properties?.label as JSONSchema6)?.default as string,
        description: (settings?.properties?.description as JSONSchema6)
          ?.default as string,
        order: (settings?.properties?.order as JSONSchema6)?.default as number,
        required: fields?.required?.includes(id) || false,
        config: {
          step: (settings?.properties?.step as JSONSchema6)?.default as number,
          min: field?.minimum,
          max: field?.maximum,
          placeholder: (settings?.properties?.placeholder as JSONSchema6)
            ?.default as string,
        },
      } satisfies Field<"number">;
    }
    default: {
      silentUnreachableError(type);
      return undefined;
    }
  }
}

export function jsonSchemaToDataTypeSchema(
  schema: JSONSchema6,
): DataTypeSchema {
  const ids = Object.keys(
    (schema.properties?.fields as JSONSchema6)?.properties ?? {},
  );
  const fields = (schema.properties?.fields as JSONSchema6) ?? {};
  const fieldsSettings =
    (schema.properties?.fieldsSettings as JSONSchema6) ?? {};

  return {
    fields: ids
      .map((id) => jsonSchemaToField(id, fields, fieldsSettings))
      .filter(isT)
      .reduce(
        (acc, field) => {
          acc[field.id] = field;

          return acc;
        },
        {} as Record<FieldId, Field<FieldType>>,
      ),
  };
}

export function dataTypeFragmentToDataType(
  fragment: DataTypeFragmentFragment,
): DataType {
  return {
    id: fragment.id as DataTypeId,
    name: fragment.name as TranslatedStr,
    description: fragment.description as TranslatedStr,
    entity: fragment.entity,
    default: fragment.default,
    // ! do we really need jsonSchema? can we use just schema instead?
    jsonSchema: fragment.jsonSchema,
    schema: jsonSchemaToDataTypeSchema(
      jsonParse(fragment.jsonSchema) as JSONSchema6,
    ),
    createdAt: toDate(fragment.createdAt),
    updatedAt: pipe(fragment.updatedAt, O.fromNullable, O.map(toDate)),
    deletedAt: pipe(fragment.deletedAt, O.fromNullable, O.map(toDate)),
  };
}
