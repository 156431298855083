import * as Listing from "../Listing";
import * as Schema from "../../../../../../../../generic-states/Schema";
import * as State from "./types/State";
import * as Actions from "./types/Actions";

export function reducer(
  s: State.State,
  a: Actions.Actions,
): State.State | Listing.State {
  if (Schema.isActions(a)) {
    return State.isReady(s)
      ? State.ready({
          ...s.payload,
          schema: Schema.reducer(s.payload.schema, a),
        })
      : s;
  }

  switch (a.type) {
    case "Ready:DataManager:DataTypes:Edit:LoadFail": {
      return s.type === "Ready:DataManager:DataTypes:Edit:Loading"
        ? State.loadError(s.payload)
        : s;
    }
    case "Ready:DataManager:DataTypes:Edit:LoadSuccess": {
      return s.type === "Ready:DataManager:DataTypes:Edit:Loading"
        ? State.ready({
            ...s.payload,
            ...a.payload,
            schema: Schema.draft(a.payload.schema),
          })
        : s;
    }
    case "Ready:DataManager:DataTypes:Edit:SetName": {
      return State.isReady(s)
        ? State.ready({ ...s.payload, name: a.payload })
        : s;
    }
    case "Ready:DataManager:DataTypes:Edit:SetDescription": {
      return State.isReady(s)
        ? State.ready({ ...s.payload, description: a.payload })
        : s;
    }
    case "Ready:DataManager:DataTypes:Edit:SetEntity": {
      return State.isReady(s)
        ? State.ready({ ...s.payload, entity: a.payload })
        : s;
    }
    case "Ready:DataManager:DataTypes:Edit:SetDefault": {
      return State.isReady(s)
        ? State.ready({ ...s.payload, default: a.payload })
        : s;
    }
    case "Ready:DataManager:DataTypes:Edit:Submit": {
      return State.isReady(s) ? State.saving(s.payload) : s;
    }
    case "Ready:DataManager:DataTypes:Edit:SaveFail": {
      return State.isSaving(s) ? State.ready(s.payload) : s;
    }
    case "Ready:DataManager:DataTypes:Edit:SaveSuccess": {
      return State.isSaving(s)
        ? State.ready({
            ...s.payload,
            ...a.payload,
            schema: Schema.draft(a.payload.schema),
          })
        : s;
    }
    case "Ready:DataManager:DataTypes:Edit:Remove": {
      return State.isReady(s) ? State.removeConfirmation(s.payload) : s;
    }
    case "Ready:DataManager:DataTypes:Edit:RemoveApprove": {
      return State.isRemoveConfirmation(s) ? State.removing(s.payload) : s;
    }
    case "Ready:DataManager:DataTypes:Edit:RemoveCancel": {
      return State.isRemoveConfirmation(s) ? State.ready(s.payload) : s;
    }
    case "Ready:DataManager:DataTypes:Edit:RemoveSuccess": {
      return State.isRemoving(s) ? Listing.init() : s;
    }
    case "Ready:DataManager:DataTypes:Edit:RemoveFail": {
      return State.isRemoving(s) ? State.ready(s.payload) : s;
    }
  }
}
