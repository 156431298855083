import { RootState, useDispatch, useSelector } from "state-manager";
import { DataTypeForm, DataTypeFormProps } from "@Containers/Form/DataTypeForm";
import { silentUnreachableError } from "utils/exceptions";
import { Loading } from "@Pages/Loading";
import * as Schema from "state-manager/generic-states/Schema";
import { useEffect } from "react";
import { useTranslation } from "i18n";
import { flow } from "fp-ts/function";
import * as Edit from "state-manager/states/Ready/states/DataManager/states/DataTypes/states/Edit";

export interface ContentProps {
  selector: (s: RootState) => Edit.State;
  dispatch: (a: Edit.Actions) => void;
}

export function Content({ selector }: ContentProps) {
  const dispatch = useDispatch();
  const formSelector = useSelector(
    flow(selector, (s) => {
      switch (s.type) {
        case "Ready:DataManager:DataTypes:Edit:Loading":
        case "Ready:DataManager:DataTypes:Edit:LoadError":
          return {
            type: "loading",
          } as const;
        case "Ready:DataManager:DataTypes:Edit:Ready":
        case "Ready:DataManager:DataTypes:Edit:Saving":
        case "Ready:DataManager:DataTypes:Edit:RemoveConfirmation":
        case "Ready:DataManager:DataTypes:Edit:Removing": {
          const dispatcher: DataTypeFormProps["dispatch"] = {
            setEntity: flow(Edit.setEntity, dispatch),
            setName: flow(Edit.setName, dispatch),
            setDescription: flow(Edit.setDescription, dispatch),
            setDefault: flow(Edit.setDefault, dispatch),
            setConfig: flow(Schema.setConfig, dispatch),
            remove: flow(Schema.remove, dispatch),
          };

          return {
            type: "ready",
            selector: flow(
              selector,
              (st) => st as typeof s,
              (s) => ({
                default: s.payload.default,
                description: s.payload.description || "",
                entity: s.payload.entity,
                name: s.payload.name,
                schema: s.payload.schema,
              }),
            ),
            dispatcher,
          } as const;
        }

        default: {
          silentUnreachableError(s);
          return {
            type: "loading",
          } as const;
        }
      }
    }),
    (a, b) => a.type === b.type,
  );

  switch (formSelector.type) {
    case "loading":
      return <Loading />;
    case "ready":
      return (
        <>
          <RemoveConfirmHandler selector={selector} />
          <DataTypeForm
            selector={formSelector.selector}
            dispatch={formSelector.dispatcher}
          />
        </>
      );
  }
}

function RemoveConfirmHandler({
  selector,
}: {
  selector: (s: RootState) => Edit.State;
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isRemoveConfirmation = useSelector(
    flow(selector, Edit.isRemoveConfirmation),
  );

  useEffect(() => {
    if (isRemoveConfirmation) {
      const confirm = window.confirm(
        t(`Are you sure you want to delete data type?`),
      );
      if (confirm) {
        dispatch(Edit.removeApprove());
      } else {
        dispatch(Edit.removeCancel());
      }
    }
  }, [isRemoveConfirmation]);

  return null;
}
