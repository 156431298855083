import { GraphQLError } from "graphql";
import { DsError, serverError } from "../type/DsError";

export function graphQLErrorToDsError(error: GraphQLError): DsError {
  switch (error?.extensions?.http?.status) {
    case 403:
      return {
        code: "UNAUTHORIZED",
      };
    case 404:
      return {
        code: "NOT_FOUND",
      };
    default: {
      return serverError(error.message, JSON.stringify(error));
    }
  }
}
