import { Selector, useSelector } from "state-manager";
import * as BuilderPreview from "state-manager/states/Ready/states/BuilderPreview";

import "@mackgmbh/warehouse-builder/style.css";

import { Warehouse } from "@mackgmbh/warehouse-builder";
import { silentUnreachableError } from "utils/exceptions";
import { flow } from "fp-ts/lib/function";
import { Loading } from "ui/layouts/Loading";
import { Field } from "ui/components/Field";
import { Label } from "ui/components/Label";
import { Combobox } from "@Containers/Form/Combobox";
import { useTranslation } from "i18n";
import { TranslatedStr } from "types/src/TranslatedStr";
import { DataType } from "types/src/DataType/DataType";
import { useState } from "react";

export interface ContentProps {
  selector: Selector<BuilderPreview.State>;
  dispatch: (a: BuilderPreview.Actions) => void;
}

export function Content(p: ContentProps) {
  const r = useSelector(
    flow(p.selector, (s) => {
      if (BuilderPreview.isLoading(s)) {
        return { type: "loading" } as const;
      }
      if (BuilderPreview.isLoadError(s)) {
        return { type: "error" } as const;
      }
      if (
        BuilderPreview.isReady(s) ||
        BuilderPreview.isCreateReposProcessing(s) ||
        BuilderPreview.isUpdateReposProcessing(s) ||
        BuilderPreview.isDeleteReposProcessing(s) ||
        BuilderPreview.isCreatedSuccessfully(s) ||
        BuilderPreview.isUpdatedSuccessfully(s) ||
        BuilderPreview.isDeletedSuccessfully(s) ||
        BuilderPreview.isActiveDataType(s)
      ) {
        return {
          type: "items",
          items: s.payload.items.map((v) => ({
            ...v,
            parentId:
              v.parentId === "00000000-0000-0000-0000-000000000000"
                ? null
                : v.parentId,
            data: Object.keys(v.data ?? {}).length ? v.data : null,
          })),
          activeDataType: s.payload.activeDataType,
          dataTypes: s.payload.dataTypes.map((d) => ({
            value: d.id,
            label: d.name,
          })),
        } as const;
      }

      silentUnreachableError(s);
      return { type: "loading" } as const;
    }),
    (a, b) => a.type === b.type && a.items === b.items,
  );

  const refetchId = useSelector(
    flow(p.selector, (s) =>
      "refetchRepoId" in s.payload ? s.payload.refetchRepoId : undefined,
    ),
  );

  switch (r.type) {
    case "loading":
      return <Loading />;
    case "items":
      return (
        <Warehouse
          refetchId={refetchId}
          getRepositories={async () => r.items}
          addRepositories={async (repos) => {
            // ! setTimeout - temporary. When we create shelfs - addRepositories & updateRepositories
            // ! run simultaneously. and one action rewrite another
            setTimeout(() => {
              // @ts-expect-error temporary
              p.dispatch(BuilderPreview.createRepos({ items: repos }));
            }, 500);

            return [];
          }}
          updateRepositories={async (repos) => {
            // @ts-expect-error temporary
            p.dispatch(BuilderPreview.updateRepos({ items: repos }));

            return [];
          }}
          removeRepositories={async (v) => {
            p.dispatch(BuilderPreview.deleteRepos({ itemsIds: v }));

            return null;
          }}
          renderRepoListHeader={() => {
            return (
              <DataTypes
                onChange={(v) =>
                  p.dispatch(BuilderPreview.changeActiveDataType(v))
                }
                activeDataType={r.activeDataType}
                dataTypes={r.dataTypes}
              />
            );
          }}
        />
      );
    case "error":
      return <div>Error</div>;
  }
}

interface DataTypesProps<T extends DataType["id"] = DataType["id"]> {
  dataTypes: { value: T; label: TranslatedStr }[];
  activeDataType: T;
  onChange: (v: T) => void;
}

export function DataTypes(p: DataTypesProps) {
  const { t } = useTranslation();
  // it a hack - because we can't read from redux here - because
  // Warehouse builder has it's own redux store
  const [value, setValue] = useState(p.activeDataType);

  const handleChange = (v: DataType["id"]) => {
    setValue(v);
    p.onChange(v);
  };

  return (
    <Field>
      <Label>{t("Data types")}</Label>
      <Combobox
        value$={() => value}
        onChange={handleChange}
        options={p.dataTypes}
      />
    </Field>
  );
}
